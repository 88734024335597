<template>
  <div class="changeAccountEmailWithToken flex justify-center items-center">
    <TokenStatus
        operation="CONFIRM_CHANGE_EMAIL"
        :token="changeAccountEmailToken"
        tokenName="changeAccountEmailToken"
        @token-is-valid="() => tokenIsValid=true"
        @token-is-invalid="() => tokenIsValid=false"
        @token-has-expired="() => tokenIsValid=false"
    ></TokenStatus>
    <Transition>
      <!--  Form to change your email adress -->
      <form
          v-if="tokenIsValid"
          class="form"
      >
        <h1 class="heading2">Confirm changing your account email address
          <hr class="gradientLine">
        </h1>
        <div class="flex items-center justify-between">
          <button
              class="button primary"
              type="button">
            Yes, I want to change my email
          </button>
        </div>
      </form>
    </Transition>
  </div>
</template>

<script>
import {reactive, ref, watch} from "vue";
import {isEmailAddress} from "@/lib/typeHelpers/stringFunctions/isEmailAddress";
import TokenStatus from "@/components/authentication/TokenStatus.vue";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {CONFIRM_CHANGE_EMAIL} from "@/store/operations";

export default {
  name: "ChangeAccountEmailWithToken",
  components: {
    TokenStatus,
  },
  props: {
    changeAccountEmailToken: {
      type: String,
      required: true
    }
  },
  setup() {
    const inputError = reactive({
      email: "",
    });
    const onEmailChange = (email) => {
      if (!isEmailAddress(email)) {
        inputError.email = "Please enter a valid email address";
      } else {
        inputError.email = "";
      }
    };

    const tokenIsValid = ref(null)
    const {setMessage, deleteMessage} = useStatusMessage(CONFIRM_CHANGE_EMAIL)

    deleteMessage()

    watch(tokenIsValid, value => {
      if (value === true) setMessage({isSuccess: true, body: "Your token is valid."})
      else if (value === false) setMessage({
        isError: true,
        body: 'Your token is invalid. It may have been used before or it may have expired. Please try again.'
      })
    })

    return {
      inputError,
      onEmailChange,
      tokenIsValid,
    }
  }
}
</script>

<style scoped>

</style>